<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <feather-icon @click="editSubscription" icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <vs-popup :active.sync="editSubscriptionPopup" title="Edit Subscription">
      <div class="popup-input edit-subscription" v-if="editSubscriptionData">
        <div class="flex items-center mb-5 mt-1 gap-3">
          <img src="https://portal-cdn.rapid.education/profile-pictures/default.jpeg" class="rounded-lg" width="45" height="45" />
          <div class="">
            <h2 class="font-semibold w-full">{{ editSubscriptionData.user.fullName }}</h2>
            <h4 class="font-light w-full">{{ editSubscriptionData.user.email }}</h4>
          </div>
        </div>
        <div class="flex items-center mb-3 gap-3">
          <img :src="editSubscriptionData.course.thumbnail" class="rounded-lg" width="45" height="45" />
          <div>
            <h2 class="font-semibold">{{ editSubscriptionData.course.name }}</h2>
            <h4 class="font-light w-full">{{ editSubscriptionData.course.code }}</h4>
          </div>
        </div>

        <p class="mb-1 font-semibold">Status</p>
        <div class="flex items-center mb-5">
          <vs-radio v-model="editSubscriptionData.status" vs-name="subscriptionStatus" vs-value="cancelled" class="mr-4"
            >Cancelled</vs-radio
          >
          <vs-radio v-model="editSubscriptionData.status" vs-name="subscriptionStatus" vs-value="active" class="mr-4">Active</vs-radio>
        </div>

        <h6 class="mb-1 font-semibold">Subscription Expiry</h6>
        <vs-collapse-item class="p-0 mb-2">
          <div slot="header" class="p-0">
            <p
              class="font-light text-success"
              v-if="editSubscriptionData.expiresAt > Date.now() && editSubscriptionData.status === 'active'"
            >
              Currently Expires on
              {{
                new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(editSubscriptionData.expiresAt)
              }}
            </p>
            <p
              class="font-light text-danger"
              v-if="editSubscriptionData.expiresAt > Date.now() && editSubscriptionData.status === 'cancelled'"
            >
              Manually Cancelled
            </p>
            <p
              class="font-light text-danger"
              v-if="editSubscriptionData.expiresAt < Date.now() && editSubscriptionData.status === 'cancelled'"
            >
              Expired on
              {{
                new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(editSubscriptionData.expiresAt)
              }}
            </p>
          </div>
          <datepicker
            v-model="editSubscriptionCalendar"
            :inline="true"
            class="datepicker-popup-inline mb-2"
            placeholder="Select Date"
          ></datepicker>
        </vs-collapse-item>

        <p class="mb-1 font-semibold">Apps</p>

        <vs-table :data="appSearchResults" :sst="true" max-items="5" @search="addStudentAppsSearch" class="mb-5">
          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.icon" class="rounded mr-3" width="40px" />
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button
                  v-if="isSubscriptionAppEnabled(attach.id)"
                  color="danger"
                  type="border"
                  size="small"
                  @click="disableApp(attach.id)"
                >
                  Disable
                </vs-button>
                <vs-button v-else color="primary" type="border" size="small" @click="enableApp(attach.id)"> Enable </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="flex justify-end mb-2">
          <vs-button class="font-bold" @click="updateRecord">Update Subscription</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  data() {
    return {
      editSubscriptionPopup: false,
      editSubscriptionData: null,
      editSubscriptionCalendar: null,
      appSearchResults: [],
      appsEnabled: [],
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    addStudentAppsSearch() {
      this.$http.get(`platform/app?filter[id]=${this.editSubscriptionData.course.appsEnabled}&pageSize=5`).then((response) => {
        if (response.data) {
          this.appSearchResults = response.data.rows;
        }
      });
    },
    isSubscriptionAppEnabled(id) {
      return this.appsEnabled.includes(id);
    },
    disableApp(id) {
      if (this.appsEnabled.includes(id)) {
        const index = this.appsEnabled.indexOf(id);
        this.appsEnabled.splice(index, 1);
      }
    },
    enableApp(id) {
      if (!this.appsEnabled.includes(id)) {
        this.appsEnabled.push(id);
      }
    },
    editSubscription() {
      this.editSubscriptionCalendar = new Date(this.params.data.expiresAt);
      this.editSubscriptionData = {
        ...this.params.data,
      };
      this.addStudentAppsSearch();
      this.appsEnabled = this.editSubscriptionData.apps.map((app) => app.id);
      this.editSubscriptionPopup = true;
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Cancel Subscription',
        text: 'Please confirm you want to cancel this subscription.',
        accept: this.deleteRecord,
        acceptText: 'Accept',
      });
    },
    deleteRecord() {
      this.$vs.loading();

      this.$http
        .delete(`billing/subscription/${this.params.value}`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.status === 200) {
            this.params.loadProductBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Cancelled',
              text: 'You have successfully cancelled this subscription',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while deleting subscription';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed To Delete Subscription',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    updateRecord() {
      this.$vs.loading();

      const pl = {
        ...this.editSubscriptionData,
        apps: this.appsEnabled,
        expiresAt: this.editSubscriptionCalendar.getTime(),
      };

      this.$http
        .patch(`billing/subscription/${this.params.value}`, pl)
        .then((response) => {
          this.$vs.loading.close();

          this.editSubscriptionPopup = false;

          if (response.status === 200) {
            this.params.loadProductBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Updated',
              text: 'You have successfully updated this subscription',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating subscription';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed To Update Subscription',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
};
</script>
<style lang="scss">
.edit-subscription {
  .vs-collapse-item--header {
    padding: 0;
  }
  .con-content--item {
    padding: 0;
  }
}
</style>
