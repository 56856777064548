<template>
  <div class="flex items-center">
    <p @click.stop.prevent class="text-inherit hover:text-primary text-lg font-bold">
      {{
        new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(params.value)
      }}
    </p>
  </div>
</template>

<script>
export default {
  computed: {},
};
</script>
