<template>
  <div class="flex items-center">
    <vs-avatar :src="course.thumbnail" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <router-link v-if="course.name" :to="url" class="text-inherit hover:text-primary" @click.stop.prevent>
      {{ this.course.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    course() {
      return this.params.value;
    },
    url() {
      return `/a/courses/${this.params.value.id}`;
    },
    authorId() {
      return this.params.data.author;
    },
  },
};
</script>
