<template>
  <div class="flex items-center">
    <vs-avatar
      :src="`https://portal-cdn.rapid.education/profile-pictures/default.jpeg`"
      class="flex-shrink-0 mr-2"
      size="30px"
      @click="$router.push(url)"
    />
    <router-link v-if="user.fullName" :to="url" class="text-inherit hover:text-primary" vi @click.stop.prevent>
      {{ this.user.fullName }}
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.params.value;
    },
    url() {
      return `/a/users/${this.params.value.id}`;
    },
  },
};
</script>
