<template>
  <div>
    <div v-if="apps.length" class="flex items-center align-middle">
      <vx-tooltip :text="app.name" v-for="(app, gIndex) in apps" style="margin-top: 2px" :key="gIndex">
        <vs-avatar :src="app.icon" :text="app.name | capitalize" class="flex-shrink-0 mr-2" size="30px" />
      </vx-tooltip>
    </div>
    <div v-else class="flex items-center align-middle">None</div>
  </div>
</template>

<script>
export default {
  computed: {
    apps() {
      return this.params.value;
    },
  },
};
</script>
