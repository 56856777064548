<!-- =========================================================================================
    File Name: ListRetailers.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card">
    <h4 class="pl-8 pt-6" v-if="title">{{ title }}</h4>

    <div class="p-8 pt-4">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
          <vs-dropdown class="cursor-pointer mr-2" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2">
                {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
                {{
                  subscriptionData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : subscriptionData.length
                }}
                of {{ totalSubscriptions }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(10);
                  loadProductBuffer();
                "
              >
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(20);
                  loadProductBuffer();
                "
              >
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(50);
                  loadProductBuffer();
                "
              >
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(100);
                  loadProductBuffer();
                "
              >
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(150);
                  loadProductBuffer();
                "
              >
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button
            @click="
              showArchived = !showArchived;
              loadProductBuffer();
            "
            class="ml-2 mr-2 round"
            color="danger"
            type="gradient"
            >{{ showArchived === true ? 'Hide Cancelled' : 'Show Cancelled' }}</vs-button
          >
          <vs-button
            color="danger"
            class="ml-2 round"
            type="gradient"
            @click="
              orderSort === 'dsc' ? (orderSort = 'asc') : (orderSort = 'dsc');
              loadProductBuffer();
            "
            >{{ orderSort === 'dsc' ? 'Sort By Latest' : 'Sort By Oldest' }}
          </vs-button>
        </div>

        <vs-button color="primary" icon-pack="feather" icon="icon-filter" class="ml-2" type="gradient" @click="filterPopup = !filterPopup"
          >Open Filter
        </vs-button>
      </div>
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="subscriptionData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable"
      >
      </ag-grid-vue>
      <vs-pagination :total="totalPages" v-model="currentPage" />
    </div>

    <!--- POPUPS --->
    <vs-popup :active.sync="filterPopup" title="Subscriptions Filter" id="SubscriptionFilterPopup">
      <vs-input
        v-model="subscriptionId"
        @input="loadProductBuffer()"
        class="my-4 mt-5 w-full"
        size="large"
        label-placeholder="Subscription Id"
      />
      <vx-card class="my-4" v-if="filterUsers">
        <div class="vx-row" v-if="filterSearchUsersSelected">
          <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
            <img src="https://portal-cdn.rapid.education/profile-pictures/default.jpeg" class="responsive rounded" alt="User Img" />
          </div>
          <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle">
            <div class="my-2">
              <h5 class="mb-2">{{ filterSearchUsersSelected.firstName }} {{ filterSearchUsersSelected.lastName }}</h5>
              <div class="text-small text-danger cursor-pointer flex items-center" @click="filterUserRemove()">
                <feather-icon icon="XIcon" svgClasses="h-5 w-5 mr-1" />Remove Filter
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center" v-else>
          <h2>No User Filtered</h2>
          <vs-button
            color="primary"
            type="gradient"
            @click="
              filterPopup = false;
              filterSearchUsersPopup = true;
            "
            icon-pack="feather"
            icon="icon-user-add"
            >Select User
          </vs-button>
        </div>
      </vx-card>
      <vx-card class="my-4" v-if="filterCourses">
        <div class="vx-row" v-if="filterSearchCoursesSelected">
          <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
            <img
              :src="
                filterSearchCoursesSelected.thumbnail !== null
                  ? filterSearchCoursesSelected.thumbnail
                  : 'https://connect-cdn.intellectualpoint.com/assets/images/misc/default-placeholder.png'
              "
              class="responsive rounded"
              alt="Course Img"
            />
          </div>
          <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle">
            <div class="my-2">
              <h5 class="mb-2">{{ filterSearchCoursesSelected.name }}</h5>
              <div class="text-small text-danger cursor-pointer flex items-center" @click="filterCourseRemove()">
                <feather-icon icon="XIcon" svgClasses="h-5 w-5 mr-1" />Remove Filter
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center" v-else>
          <h2>No Course Filtered</h2>
          <vs-button
            color="primary"
            type="gradient"
            @click="
              filterPopup = false;
              filterSearchCoursesPopup = true;
            "
            icon-pack="feather"
            icon="icon-user-add"
            >Select Course
          </vs-button>
        </div>
      </vx-card>
      <vs-button color="primary" type="gradient" @click="filterPopup = false" class="mt-8">Close</vs-button>
    </vs-popup>
    <vs-popup :active.sync="filterSearchUsersPopup" classContent="popup-example" title="Search for User by Name">
      <vs-table :data="filterSearchUsersResults" :sst="true" max-items="5" search @search="filterSearchUsers">
        <template slot="header">
          <h3 class="ml-4">Users</h3>
        </template>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="attach in data" :key="attach.id">
            <vs-td :data="`${attach.firstName} ${attach.lastName}`">
              {{ `${attach.firstName} ${attach.lastName}` }}
            </vs-td>

            <vs-td :data="attach.email">
              {{ attach.email }}
            </vs-td>

            <vs-td>
              <vs-button
                color="primary"
                size="small"
                type="border"
                icon-pack="feather"
                icon="icon-filter"
                @click="filterUserSelected(attach)"
              >
                Filter
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
    <vs-popup :active.sync="filterSearchCoursesPopup" classContent="popup-example" title="Search for Course by Name">
      <vs-table :data="filterSearchCoursesResults" :sst="true" max-items="5" search @search="filterSearchCourses">
        <template slot="header">
          <h3 class="ml-4">Courses</h3>
        </template>

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="attach in data" :key="attach.id">
            <vs-td :data="attach.id">
              {{ attach.id }}
            </vs-td>

            <vs-td :data="`${attach.name}`">
              <div class="flex align-middle items-center">
                <img :src="attach.thumbnail" class="rounded mr-3" width="40px" />
                {{ `${attach.name}` }}
              </div>
            </vs-td>

            <vs-td>
              <vs-button
                color="primary"
                size="small"
                type="border"
                icon-pack="feather"
                icon="icon-filter"
                @click="filterCoursesSelected(attach)"
              >
                Filter
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import TableUser from './components/TableUser.vue';
import TableCourse from './components/TableCourse.vue';
import TableActions from './components/TableActions.vue';
import TableDate from './components/TableDate.vue';
import TableID from './components/TableID';
import TableApps from './components/TableApps';
import TableProgress from './components/TableProgress';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableUser,
    TableCourse,
    TableDate,
    TableActions,
    TableID,
    TableApps,
    TableProgress,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    subscriptionId: {
      type: String,
      default: '',
      required: false,
    },
    courseId: {
      type: String,
      default: '',
      required: false,
    },
    userId: {
      type: String,
      default: '',
      required: false,
    },
    filterUsers: {
      type: Boolean,
      default: true,
      required: false,
    },
    filterCourses: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      showArchived: false,

      subscriptionData: [],
      totalSubscriptions: 0,

      orderSort: 'asc',

      filterPopup: false,

      filterSearchUsersPopup: false,
      filterSearchUsersResults: [],
      filterSearchUsersSelected: null,

      filterSearchCoursesPopup: false,
      filterSearchCoursesResults: [],
      filterSearchCoursesSelected: null,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'User',
          field: 'user',
          filter: true,
          cellRendererFramework: 'TableUser',
        },
        // {
        //   headerName: 'User Classrooms',
        //   //field: '',
        //   filter: true,
        // },
        {
          headerName: 'Course',
          field: 'course',
          filter: true,
          cellRendererFramework: 'TableCourse',
        },
        {
          headerName: 'Apps',
          field: 'apps',
          filter: true,
          cellRendererFramework: 'TableApps',
        },
        {
          headerName: 'Start Date',
          field: 'startedAt',
          filter: true,
          cellRendererFramework: 'TableDate',
        },
        {
          headerName: 'Expiry Date',
          field: 'expiresAt',
          filter: true,
          cellRendererFramework: 'TableDate',
        },
        // {
        //   headerName: 'Progress',
        //   field: 'id',
        //   filter: true,
        //   cellRendererFramework: 'TableProgress',
        // },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Actions',
          field: 'id',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableUser,
        TableCourse,
        TableDate,
        TableActions,
        TableID,
        TableApps,
        TableProgress,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    loadProductBuffer(page = 0) {
      if (page === 0) {
        page = this.currentPage;
      }

      this.$http
        .get(
          `billing/subscription?amount=${this.paginationPageSize}&page=${page}&filter[id]=${this.subscriptionId}&filter[user]=${
            this.userId
          }&filter[course]=${this.courseId}${this.showArchived ? '' : '&filter[status]=active'}&sort=${
            this.orderSort === 'asc' ? '-id' : 'id'
          }&populate=user,course,apps`,
        )
        .then((response) => {
          if (response.data) {
            this.subscriptionData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalSubscriptions = response.data.total;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading the subscriptions';
          if (exception.response) {
            error = exception.response.data.messafe;
          }

          return this.$vs.notify({
            title: 'Failed to load subscriptions',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    filterSearchUsers(term = '') {
      this.$http.get(`user?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.filterSearchUsersResults = response.data.rows;
        }
      });
    },
    filterUserSelected(user) {
      this.filterSearchUsersSelected = user;
      this.userId = user.id;
      this.filterPopup = true;
      this.filterSearchUsersPopup = false;
      this.loadProductBuffer();
    },
    filterUserRemove() {
      this.userId = '';
      this.filterSearchUsersSelected = null;
      this.loadProductBuffer();
    },
    filterSearchCourses(term) {
      this.$http.get(`course?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.filterSearchCoursesResults = response.data.rows;
        }
      });
    },
    filterCoursesSelected(course) {
      this.filterSearchCoursesSelected = course;
      this.courseId = course.id;
      this.filterPopup = true;
      this.filterSearchCoursesPopup = false;
      this.loadProductBuffer();
    },
    filterCourseRemove() {
      this.courseId = '';
      this.filterSearchCoursesSelected = null;
      this.loadProductBuffer();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.filterCourses) {
      this.filterSearchCourses('');
    }

    if (this.filterUsers) {
      this.filterSearchUsers('');
    }

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
};
</script>

<style lang="scss">

.theme-dark {
  #SubscriptionFilterPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
